import { useQuery } from '@tanstack/react-query';

import { getSalesChannels, getSalesChannelsKey } from '../components/RMS/salesChannels.services';
import { FIVE_MINUTES } from '../helpers/timeConstants';

export enum SalesChannelType {
  POS = 'POS',
  UBER_EATS = 'UberEats',
  DELIVEROO = 'Deliveroo',
  JUST_EATS = 'JustEats',
  FLIPDISH_WEB_APP = 'FlipdishWebApp',
  FLIPDISH_KIOSK = 'FlipdishKIOSK',
  FLIPDISH_MOBILE_APP = 'FlipdishMobileApp',
  EXTERNAL_APP = 'ExternalApp',
  WEB_APP = 'WebApp',
  KIOSK = 'Kiosk',
  MOBILE_APP = 'MobileApp',
  Web = 'Web',
  App = 'App',
  Pos = 'Pos',
}

export enum OrderIngestType {
  URBAN_PIPER = 'UrbanPiper',
}

export const useQueryRMSSalesChannels = (props: {
  orgId: string;
  propertyId: string;
  filterByType?: SalesChannelType[];
}) => {
  const { orgId, propertyId, filterByType = [] } = props;

  const {
    isFetching: isLoadingSalesChannels,
    data: salesChannels,
    error: salesChannelsError,
  } = useQuery({
    queryKey: [getSalesChannelsKey, orgId, propertyId],
    queryFn: () => getSalesChannels({ orgId, propertyId }),
    // this api only takes org api property ids
    enabled: !!(propertyId && orgId) && propertyId?.startsWith('p'),
    retry: 1,
    staleTime: FIVE_MINUTES,
  });

  const storefronts = salesChannels?.data?.data;

  const filteredStorefronts = filterByType?.length
    ? storefronts?.filter(
        (storefront) =>
          filterByType?.length === 0 ||
          (storefront?.type && filterByType?.includes(storefront.type as any))
      )
    : storefronts;
  return {
    isLoading: isLoadingSalesChannels,
    error: salesChannelsError,
    data: filteredStorefronts,
  };
};

export const domainTag = 'frontend_portal_';

export const frontEndFlags = [
  'addSalesChannelTypeToStoreVisibility',
  'addStoresButton',
  'alertBannerInvoice',
  'createCampaignAndVoucherBtn',
  'deliveryLeadTime',
  'freeDeliveryFeeVoucher',
  'hideNextOptionSetButtonOnSkip',
  'hideStoresPage2',
  'internationalisedAddress',
  'loyaltyMaxDiscount',
  'menuItemVoucher',
  'nestedModifiers',
  'orderFulfillmentStatusConfigurations',
  'orderFulfillmentStatusOrderDetails',
  'paygreenFeatures',
  'showBannerAlertForIntegratedMenus',
  'showAllMenusInEditor',
  'showPayoutsStoreOptions',
  'showPosInPayoutReport',
  'showPropertiesScreen',
  'showRMS',
  'tablePrefix',
  'tippingCustom',
  'tippingReduceValues',
  'tippingRoundUp',
  'useInjectedRecaptchaKey',
  'useNewPayoutV3Report',
  'voucherChannel',
  'voucherDiscoverable',
  'voucherLimitAvailability',
  'voucherValidPeriod',
  'showSmsCampaigns',
  'homeOnBrandChange',
  'showRMSMenuSelectorForFDSalesChannels',
  'showSalesChannelStructure',
  'showPhoneMarketing',
  'allowUpdateToBankingVatNumber',
  'redirectOrgToBrand',
  'enablePostcodePolygons',
  'enablePostcodePolygonsMultiple',
  'useStoresToSalesChannelRedirect',
  'showPropertyOpeningHours',
  'showGoogleListingManagement',
  'enableMicroFrontendExampleManifestFile',
  'enableManifestMenuEditor',
  'enableManifestRestaurantLayoutDesigner',
  'disablePropertyManagerLevelAddOns',
] as const;

export type FrontEndFlag = (typeof frontEndFlags)[number];

export const permissionsIncludingPropertyManager: AppState['permissions'] = [
  'Owner',
  'ManagedOwner',
  'FlipdishStaff',
  'StoreOwner',
];

export const franchisorPermissions: AppState['permissions'] = [
  'Owner',
  'ManagedOwner',
  'FlipdishStaff',
];

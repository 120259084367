import React from 'react';

import { styled } from '@mui/material/styles';
import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../helpers/utilities';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';

const RestaurantLayout = lazyWithRetry(() => import('./RestaurantLayout'));

const StyledPageLayout = styled(PageLayout)`
  display: flex;
  flex-direction: column;

  & > div:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 0;
  }

  & {
    height: 100%;
  }
`;

type RouteParams = {
  appId?: string;
  propertyId?: string;
};

type Props = RouteComponentProps<RouteParams>;

const RestaurantLayoutRoutes: React.FC<Props> = (props: Props) => {
  const {
    match: { path, params },
  } = props;

  return (
    <ErrorBoundary identifier="restaurant-layout-designer">
      <Switch>
        <Route
          path={`${path}`}
          render={() => (
            <StyledPageLayout
              title={<Translate id="Layout_Configuration" />}
              strictToParent
              toParent="./"
              noMaxWidth={true}
              wrapperClassName="restaurant-layout"
            >
              <LazyComponent>
                <RestaurantLayout propertyId={params.propertyId} />
              </LazyComponent>
            </StyledPageLayout>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default RestaurantLayoutRoutes;

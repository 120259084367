import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { loadStoresNetSales } from '../actions';
import { getStoreByIdFactoryWithNetSales } from '../selectors';
import StoreNetSalesLineChart from './StoreNetSalesLineChart';

export interface IStoreNetSalesProps {
  storeId: number;
  inView?: boolean;
  children?: React.ReactNode;
}
type Props = MappedState & MappedDispatch & IStoreNetSalesProps;
const StoreNetSales: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { storeId, store, getNetSales, children, inView } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (inView && storeId && storeId > 0) {
      getNetSales(storeId)
        .then(() => setLoaded(true))
        .catch(() => setLoaded(true));
    }
  }, [storeId, inView]);

  return (
    <>
      {children}
      {store?.NetSales?.CurrWeekTotal !== 0 && (
        <StoreNetSalesLineChart data={store} loaded={loaded} />
      )}
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state, props: IStoreNetSalesProps) => {
  const getStoreWithSalesById = getStoreByIdFactoryWithNetSales(props.storeId);

  return (state: AppState) => {
    const store = getStoreWithSalesById(state);
    return {
      store,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch: ThunkDispatch) => {
  return () => ({
    getNetSales: (storeIds) => dispatch(loadStoresNetSales(storeIds)),
  });
};

export default compose<Props, IStoreNetSalesProps>(
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
)(StoreNetSales);

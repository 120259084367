import React from 'react';

import WebsiteIcon from '@mui/icons-material/LanguageOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';

import { KioskIcon } from '../../components/Kiosks/Components/KioskNestedMenuIcon';
import { SalesChannelType } from '../../custom-hooks/useQueryRMSSalesChannels';

export function getSalesChannelTypeIcon(salesChannelType: string): JSX.Element {
  switch (salesChannelType) {
    case SalesChannelType.POS:
    case SalesChannelType.Pos:
      return <TvOutlinedIcon />;
    case SalesChannelType.UBER_EATS:
    case SalesChannelType.DELIVEROO:
    case SalesChannelType.JUST_EATS:
      return <StorefrontOutlinedIcon />;
    case SalesChannelType.FLIPDISH_WEB_APP:
    case SalesChannelType.Web:
    case SalesChannelType.WEB_APP:
      return <WebsiteIcon />;
    case SalesChannelType.FLIPDISH_KIOSK:
    case SalesChannelType.KIOSK:
      return <KioskIcon />;
    case SalesChannelType.FLIPDISH_MOBILE_APP:
    case SalesChannelType.EXTERNAL_APP:
    case SalesChannelType.MOBILE_APP:
    case SalesChannelType.App:
      return <PhoneIphoneIcon />;
    default:
      return <></>;
  }
}

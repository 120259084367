import React, { useEffect, useMemo, useRef, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { type RouteComponentProps, Switch, withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { accountActions } from '../actions/account.actions';
import { appsActions } from '../actions/apps.actions';
import Loading from '../components/Loading';
import {
  getOnboardingConfigs,
  getOnboardingConfigsQueryKey,
  getOnboardingItems,
  getOnboardingItemsQueryKey,
} from '../components/OnboardingV2/onboarding.service';
import { isAcceptInviteFlowInProgress } from '../components/Teammates/teammateutils';
import { usePageTracking } from '../custom-hooks/usePageTracking';
import { gdpr } from '../helpers/gdpr';
import { store } from '../helpers/store';
import { notifyError } from '../layouts/Notify/actions';
import { getAccount } from '../selectors/account.selector';
import { flagService } from '../services';
import { useTracking } from '../services/amplitude/useTracking';
import { sendDatadogRumData } from '../services/datadogRUM';
import { initialise as initaliseDataDogLogging } from '../services/loggerService';
import { accountRoutesConst } from './account.routes';
import {
  acceptAllInvitesAndRouteToFirstAcceptedBrand,
  createOrgAndBrand,
  createRoute,
  DEFAULT_MIN_LOADING_TIMEOUT,
  getAppId,
  getIsLoggingIn,
  isFirstSetupV2Route,
  isOrgInPathName,
  isPublicRoute,
} from './utils';

type Props = RouteComponentProps & MappedState & MappedDispatch;
const AuthRouter: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const isAcceptInviteInProgress = isAcceptInviteFlowInProgress();

  const {
    account,
    currentApp,
    registeredRoutes,
    location,
    history,
    permissions,
    setCurrentApp,
    dispatchNotifyError,
  } = props;

  const { identifyUser } = useTracking(account);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  //#region onboarding v2
  const { data: onboardingConfigs, isFetched: onboardConfigsFetched } = useQuery({
    queryKey: [getOnboardingConfigsQueryKey, currentApp.AppId],
    queryFn: async () => {
      return await getOnboardingConfigs(currentApp.AppId ?? '');
    },
    enabled: !!currentApp.AppId,
  });

  const onboardingConfig = useMemo(() => {
    return onboardingConfigs?.Data.find(
      (cfg) => cfg.ConfigType === 'OnboardingWizard' && cfg.IsEnabled
    );
  }, [onboardingConfigs]);

  useEffect(() => {
    if (onboardConfigsFetched && !onboardingConfig?.IsEnabled) {
      setInitialized(true);
    }
  }, [onboardConfigsFetched, onboardingConfig]);

  const { data: onboardingV2Steps, isFetched: isOnboardingV2StepsFetched } = useQuery({
    queryKey: [getOnboardingItemsQueryKey, currentApp.AppId],
    queryFn: async () => {
      const response = await getOnboardingItems(
        currentApp.AppId ?? '',
        onboardingConfig?.WebActivationWizardMilestoneId
      );
      return response?.Data?.RootItems?.[0]?.Items?.[0]?.Items?.filter(
        (step) => step?.Status !== 'Completed'
      );
    },
    enabled: !!onboardingConfig?.IsEnabled,
  });

  useEffect(() => {
    if (isOnboardingV2StepsFetched) {
      setInitialized(true);
    }
  }, [isOnboardingV2StepsFetched]);

  const isFirstSetupV2 = !!onboardingV2Steps?.length;

  //#endregion

  //#region initialize
  const startLoadingTime = useRef<number>();
  const pathBefore = useRef<string>();
  useEffect(() => {
    startLoadingTime.current = Date.now();

    (function setPathBefore() {
      const { pathname } = location;
      // now any route matches private /:appId/ :(
      if (
        pathname !== '/' &&
        !isOrgInPathName(pathname) &&
        !isPublicRoute(pathname) &&
        !isFirstSetupV2Route(pathname)
      ) {
        pathBefore.current = pathname;
      }
    })();

    props.getAccountDetails().catch(() => {
      // not login
      setTimeout(
        () => {
          setInitialized(true);
        },
        DEFAULT_MIN_LOADING_TIMEOUT - (Date.now() - startLoadingTime.current!)
      );
    });
  }, []);

  useEffect(() => {
    // Prevents briefly showing portal home screen before onboarding screens display
    if (getIsLoggingIn() && account.authorized && !onboardConfigsFetched) {
      setInitialized(false);
      setLoading(true);
    }
  }, [account, onboardConfigsFetched]);

  useEffect(() => {
    if (account.authorized && account.AccountId) {
      (async () => {
        if (!currentApp.AppId) {
          // This will return FD-global app for FD staff always
          // non fd but existing client will get one or more apps back based on their account cookie
          // new signup will get no apps back
          const app = await props.setCurrentApp(getAppId(pathBefore));

          // new clients
          if (!app?.AppId && !isAcceptInviteInProgress && getIsLoggingIn()) {
            if (account.IsSelfServeUser) {
              await createOrgAndBrand({ account, setCurrentApp });
            } else {
              await acceptAllInvitesAndRouteToFirstAcceptedBrand({
                email: account?.Email ?? '',
                dispatchNotifyError,
              });
            }
          }

          if (onboardingV2Steps?.length === 0 && pathBefore.current) {
            const path = pathBefore.current;
            pathBefore.current = undefined;
            if (path !== location.pathname) {
              history.replace(path);
            }
          }
        }
      })();
    }
  }, [account, onboardingV2Steps, currentApp]);
  //#endregion

  //#region page events trackers
  usePageTracking();
  //#endregion

  //#region GDPR -init on first load if not on create_password page
  gdpr.useInitGdpr();
  //#endregion

  //#region Split IO -init on first load to retrieve feature flags from Split
  useEffect(() => {
    const isLoggedIn = !!account?.Email;
    const TRAFFIC_TYPE = flagService.TRAFFIC_TYPE;
    const splitParams = {
      appId: currentApp.AppId,
      orgId: currentApp.OrgId,
      isFlipdishStaff: permissions.includes('FlipdishStaff'),
      userId: account.AccountId || account.DeviceId,
      languageId: account.Language,
      trafficType: isLoggedIn
        ? TRAFFIC_TYPE.USER_TRAFFIC_TYPE
        : TRAFFIC_TYPE.ANONYMOUS_USER_TRAFFIC_TYPE,
    };

    flagService.initialiseSplit(store, splitParams);
    sendDatadogRumData(account);
    identifyUser();
  }, [account?.AccountId, currentApp?.AppId, permissions]);
  //#endregion

  //#region DataDog -init on first load to allow logging of errors
  useEffect(() => {
    initaliseDataDogLogging();
  }, []);
  //#endregion

  useEffect(() => {
    if (loading && isAcceptInviteInProgress && account.authorized) {
      setLoading(false);
    }
  }, [loading, isAcceptInviteInProgress, account.authorized]);

  const routes = useMemo(() => {
    if (loading && !isAcceptInviteInProgress) {
      return null;
    }

    if (!account.authorized) {
      datadogRum.setUserProperty('setup_status', 'not_authenticated');
      datadogRum.setGlobalContextProperty('setup_status', 'not_authenticated');
      return registeredRoutes
        .filter((r) => r.group === 'public' || r.group === 'publicAndPrivate')
        .map((r) => createRoute(r, permissions));
    }

    if (isFirstSetupV2) {
      datadogRum.setUserProperty('setup_status', 'first_time_setup_V2');
      datadogRum.setGlobalContextProperty('setup_status', 'first_time_setup_V2');
      return registeredRoutes
        .filter((r) => r.group === 'first_time_setup_V2' || r.path === accountRoutesConst.Logout)
        .map((r) => createRoute(r, permissions));
    }

    datadogRum.setUserProperty('setup_status', 'logged_in');
    datadogRum.setGlobalContextProperty('setup_status', 'logged_in');

    return registeredRoutes
      .filter((r) => r.group === 'private' || r.group === 'publicAndPrivate')
      .map((r) => createRoute(r, permissions));
  }, [loading, account, registeredRoutes, permissions, isFirstSetupV2]);

  if (loading) {
    return (
      <Loading
        fullscreen
        animate={!initialized}
        onAnimationEnd={() => {
          // When setInitialized(true) onAnimationEnd sets loading false
          setLoading(false);
        }}
      />
    );
  }

  return <Switch>{routes}</Switch>;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    account: getAccount(state),
    currentApp: state.currentApp,
    permissions: state.permissions,
    registeredRoutes: state.routing.routes,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getAccountDetails: () => dispatch(accountActions.getAccountDetails(true)),
  setCurrentApp: (appId: string | undefined) => dispatch(appsActions.setCurrentApp(appId)),
  dispatchNotifyError: () =>
    dispatch(notifyError({ message: 'Something_went_wrong', translate: true })),
});

export default compose<Props, {}>(
  setDisplayName('AuthRouter'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthRouter);
